<template>
  <div>
    <nav-header></nav-header>
    <div class="margin" >
      <div class="title">
        <h2>{{detailContent.title}}</h2>
        <span>
           {{detailContent.resource}}
        </span>
        <span v-if="detailContent.resource">•</span>
        <span>{{detailContent.date}}</span>
      </div>
      <div class="detail" v-html="detailContent.mediaContent">
      </div>
    </div>
  </div>

</template>

<script>
import NavHeader from '../components/ActHeader'
import { getdataInfo } from "../api/home";
export default {
  name: "Detail",
  components:{
    NavHeader
  },
  data() {
    return {
      index:1,
      detailContent:'',
      id:7,
      pageType: 'activity',
    }

  },

  mounted() {
    this.index =this.$route.query.id
    this.getdata(this.index)
    //let data = JSON.parse(this.$route.query.content)
    //this.detailContent = data
  },

  methods:{
    async getdata(index) {
      const data = await getdataInfo({ id: this.id, pageType: this.pageType })
      const zh_envdata= JSON.parse(data.esgContent.cnContent)
      //zh_envdata.newLists = zh_envdata.newLists.reverse()
      zh_envdata.newLists.sort(function(a, b) {
        return b.date < a.date ? -1 : 1
      })
      const en_envdata= JSON.parse(data.esgContent.enContent)
      en_envdata.newLists.sort(function(a, b) {
        return b.date < a.date ? -1 : 1
      })
     // en_envdata.newLists=en_envdata.newLists.reverse()

      if(this.$i18n.locale=='zh'){
        this.detailContent = zh_envdata.newLists[index]
      }else {
        // this.detailContent = en_envdata.newLists[index]
        this.detailContent = en_envdata.newLists[index]
      }
    }
  },

}
</script>

<style  lang="scss">
.margin{
  margin-top: 40px;
}
.detail{
  padding-top:30px;
  width: 1086px;
  margin: 0 auto;
  color: #353535;
  font-family: Helvetica;
  padding-bottom: 30px;
  P{
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 32px;
    text-indent: 2em;
    font-family:Helvetica;
    text-align: left;
    margin-bottom: 18px;
    img{
      display: block;
      width: 100%;
    }
  }
  section{
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 32px;
    text-indent: 2em;
    font-family:Helvetica;
    text-align: center;
    margin-bottom: 18px;
    img{
      display: block;
      width: 100%;
      margin-bottom: 10px;

    }
  }

}
.title{
  text-align: center;
}
.title h2{
  font-size:30px;
  padding:26px;
  font-family:Helvetica;

}
.title span{
  //font-size: 16px;
  font-family:Helvetica;
}
.detail-container{
  margin-top: 30px;
}

.detailBanner{
  width: 100%;
  padding: 30px 0;
}
.detailBanner img{
  display: block;
  width: 100%;
  height: 700px;
}
.detailBanner .wscnph{
  display: block;
  width: 100%;
  height: 700px;
}
.detail section {
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 32px;
  text-indent: 2em;
  text-align: center;
  margin-bottom: 18px;
}

@media (max-width: 680px) {
  .margin{
    margin-top: 0px;
  }
  .detail{
    box-sizing: border-box;
    padding: 30px 10px;
    width:100%;
  }
  .title h2{
    font-size:16px;
    padding:26px;
    font-family:Helvetica;
  }
  .title span{
    font-size: 16px;
    font-family:Helvetica;
  }
  .detailBanner{
    width: 100%;
    padding: 10px 0;
  }
  .detailBanner img{
    display: block;
    width: 100%;
    height: auto;
  }
  .detail{

    P{
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 32px;
      text-indent: 2em;
      font-family:Helvetica;
      text-align: left;
      margin-bottom: 18px;
      img{
        display: block;
        width: 100%;
        height: auto;
      }
    }
    section{
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 32px;
      text-indent: 2em;
      font-family:Helvetica;
      text-align: left;
      margin-bottom: 18px;
      img{
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>
